import { createClient } from '@supabase/supabase-js';

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_ANON_KEY;

export const supabase = createClient(supabaseUrl, supabaseAnonKey, {
  auth: {
    autoRefreshToken: true,
    persistSession: true,
    detectSessionInUrl: false
  },
});

// Export a function to handle complete logout
export const handleCompleteLogout = async () => {
  try {
    // 1. Sign out from Supabase
    await supabase.auth.signOut();
    
    // 2. Clear ALL storage
    localStorage.clear();
    sessionStorage.clear();
    
    // 3. Clear cookies
    document.cookie.split(";").forEach(function(c) { 
      document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
    });
    
    // 4. Force reload to clear any in-memory state
    window.location.href = '/login';
  } catch (error) {
    console.error('Error during logout:', error);
    // Force reload even if there's an error
    window.location.href = '/login';
  }
};
