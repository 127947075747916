import React from 'react';
import '../styles/pages/MoraleAI.css';

const MoraleAI = () => {
  const gptUrl = "https://chatgpt.com/g/g-677244dfe67881919fa3a7fa9b00172c-morale-insight-assistant";

  const openGPTChat = () => {
    window.open(gptUrl, '_blank', 'width=800,height=600');
  };

  return (
    <div className="page-container">
      <div className="center-content">
        <h2>Welcome to Morale AI Assistant</h2>
        <p>Your intelligent companion for property insights and analysis.</p>
        <button onClick={openGPTChat} className="launch-button">
          Launch Morale AI Assistant
        </button>
      </div>
    </div>
  );
};

export default MoraleAI;
