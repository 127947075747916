import React, { useEffect, useState } from 'react';
import { supabase } from '../supabaseClient';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../styles/pages/Home.css';
import { useKlaviyo } from '../context/KlaviyoContext';

const Home = () => {
  const navigate = useNavigate();
  const [klaviyoApiKey, setKlaviyoApiKey] = useState(null);
  const [error, setError] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [adminStats, setAdminStats] = useState(null);
  const [statsLoading, setStatsLoading] = useState(true);
  const [userName, setUserName] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState('');

  const {
    profileData,
    setProfileData,
    customProperties,
    setCustomProperties,
    loading,
    setLoading,
    lastRefresh,
    setLastRefresh
  } = useKlaviyo();

  const [loadingProgress, setLoadingProgress] = useState(0);
  const [loadingMessage, setLoadingMessage] = useState('');

  const klaviyoProxyUrl = `${process.env.REACT_APP_SUPABASE_URL}/functions/v1/klaviyo-proxy`;

  const fetchKlaviyoApiKey = async () => {
    try {
      // Get session first
      const { data: { session }, error: sessionError } = await supabase.auth.getSession();
      if (sessionError) throw sessionError;

      if (!session) {
        throw new Error('No session found');
      }

      console.log('Auth session user ID:', session.user.id);
      
      // Debug: Get all users to check IDs
      const { data: allUsers, error: allUsersError } = await supabase
        .from('users')
        .select('id, email')
        .limit(20);

      if (allUsersError) {
        console.error('Error fetching users:', allUsersError);
      } else {
        console.log('All users in database:', allUsers);
      }
      
      // Check for user data
      const { data: checkData, error: checkError } = await supabase
        .from('users')
        .select('*')
        .eq('id', session.user.id);

      if (checkError) {
        console.error('Error checking user:', checkError.message);
        setError('Failed to verify user');
        return;
      }

      if (!checkData || checkData.length === 0) {
        // Try finding user by email instead
        const { data: emailData, error: emailError } = await supabase
          .from('users')
          .select('*')
          .eq('email', session.user.email);

        if (!emailError && emailData && emailData.length > 0) {
          console.log('Found user by email instead of ID:', emailData[0]);
          // Update the user's ID in the database
          const { error: updateError } = await supabase
            .from('users')
            .update({ id: session.user.id })
            .eq('id', emailData[0].id);

          if (updateError) {
            console.error('Error updating user ID:', updateError);
          } else {
            console.log('Updated user ID successfully');
            // Use the user data we found
            const userData = emailData[0];
            if (userData.klaviyo_api_key) {
              setKlaviyoApiKey(userData.klaviyo_api_key);
              return;
            }
          }
        }
        
        console.error('No user found with ID:', session.user.id);
        setError('User not found in database');
        return;
      }

      // Use the first matching user's data
      const userData = checkData[0];
      
      if (!userData.klaviyo_api_key) {
        console.error('No Klaviyo API key found for user');
        throw new Error('No Klaviyo API key found for user');
      }

      console.log('Successfully fetched Klaviyo API key');
      setKlaviyoApiKey(userData.klaviyo_api_key);
    } catch (error) {
      console.error('Error fetching Klaviyo API key:', error);
      setError('Failed to fetch Klaviyo API key: ' + error.message);
    }
  };

  const fetchKlaviyoData = async (force = false) => {
    if (!klaviyoApiKey) {
      setError('No Klaviyo API key available');
      return;
    }
    
    setLoading(true);
    setError(null);
    setLoadingMessage('Fetching Klaviyo data...');
    setLoadingProgress(0);

    try {
      const { data: { session } } = await supabase.auth.getSession();
      if (!session) {
        throw new Error('No active session');
      }

      // Step 1: Get profile fields (custom properties)
      setLoadingMessage('Fetching custom properties...');
      setLoadingProgress(30);
      
      console.log('Making Klaviyo API request to fetch fresh data');

      async function fetchProfiles() {
        console.log('Starting profile fetch...');
        let allProperties = new Map();
        let totalProfiles = 0;
        
        try {
          const { data: { session } } = await supabase.auth.getSession();
          if (!session) throw new Error('No session found');

          // Try to get profiles from Supabase first
          const { data: supabaseProfiles, error: supabaseError } = await supabase
            .from('secure_klaviyo_profiles')
            .select('*')
            .order('updated', { ascending: false })
            .limit(100);

          let profiles = [];
          
          if (supabaseError || !supabaseProfiles?.length) {
            console.log('No Supabase profiles found or error, falling back to Klaviyo API');
            
            // Fallback to Klaviyo API
            const response = await axios.post(klaviyoProxyUrl, {
              klaviyoApiKey,
              endpoint: '/api/profiles',
              method: 'GET',
              params: {
                'fields[profile]': 'email,phone_number,external_id,properties,updated,created,last_event_date',
                'sort': '-updated',
                'page[size]': 100
              }
            }, {
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${session.access_token}`
              }
            });

            profiles = response.data?.data || [];
            console.log(`Fetched ${profiles.length} profiles from Klaviyo API`);
          } else {
            // Transform Supabase profiles to match Klaviyo format
            profiles = supabaseProfiles.map(profile => ({
              id: profile.klaviyo_id,
              attributes: {
                email: profile.decrypted_email,
                phone_number: profile.decrypted_phone_number,
                external_id: profile.external_id,
                properties: profile.properties,
                updated: profile.updated,
                created: profile.created,
                last_event_date: profile.last_event_date
              }
            }));
            console.log(`Fetched ${profiles.length} profiles from Supabase`);
          }

          // Store profiles for viewing
          setProfileData(profiles);

          // Process profiles
          profiles.forEach(profile => {
            const properties = profile.attributes?.properties || {};
            const email = profile.attributes?.email;
            const updated = profile.attributes?.updated;
            
            Object.entries(properties).forEach(([key, value]) => {
              if (value === null || value === undefined) return;
              
              if (!allProperties.has(key)) {
                allProperties.set(key, {
                  count: 0,
                  values: new Set(),
                  types: new Set(),
                  isGlobal: key.startsWith('$'),
                  lastUpdated: updated || new Date().toISOString()
                });
              }

              const propStats = allProperties.get(key);
              propStats.count++;
              propStats.values.add(JSON.stringify(value));
              propStats.types.add(typeof value);
              if (updated && (!propStats.lastUpdated || updated > propStats.lastUpdated)) {
                propStats.lastUpdated = updated;
              }
            });
          });

          // Convert Map to array for display
          const displayStats = Array.from(allProperties.entries())
            .map(([name, stats]) => {
              const displayValue = stats.values.size > 3
                ? `${Array.from(stats.values).slice(0, 3).join(', ')}... (${stats.values.size} unique values)`
                : Array.from(stats.values).join(', ');

              return {
                name,
                count: stats.count,
                location: displayValue,
                isCustom: !stats.isGlobal,
                types: Array.from(stats.types).join(', '),
                uniqueValues: stats.values.size,
                lastUpdated: stats.lastUpdated
              };
            })
            .sort((a, b) => {
              // Sort global properties first, then by last updated
              if (a.isCustom !== b.isCustom) {
                return a.isCustom ? 1 : -1;
              }
              if (a.lastUpdated !== b.lastUpdated) {
                return new Date(b.lastUpdated) - new Date(a.lastUpdated);
              }
              return b.count - a.count;
            });

          console.log(`Completed processing ${profiles.length} profiles with ${displayStats.length} custom properties`);
          setCustomProperties(displayStats);
          return displayStats;

        } catch (error) {
          console.error('Error fetching profiles:', error);
          setLoadingMessage('Error fetching profiles');
          throw error;
        }
      }

      // Fetch profiles with progressive loading
      const allProfiles = await fetchProfiles();
      console.log('Total profiles processed:', allProfiles.length);

    } catch (error) {
      console.error('Error fetching Klaviyo data:', error);
      setError(error.response?.data?.message || error.message);
    } finally {
      setLoading(false);
      setLoadingProgress(100);
      setLoadingMessage('');
    }
  };

  useEffect(() => {
    const checkSession = async () => {
      const { data: { session } } = await supabase.auth.getSession();
      if (!session) {
        navigate('/login');
      }
    };

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      if (!session) {
        navigate('/login');
      }
    });

    checkSession();

    return () => subscription.unsubscribe();
  }, [navigate]);

  useEffect(() => {
    const initializeData = async () => {
      await fetchKlaviyoApiKey();
      if (klaviyoApiKey) {
        await fetchKlaviyoData(true);
      }
    };

    initializeData();
  }, [klaviyoApiKey]);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const { data: { session } } = await supabase.auth.getSession();
        if (!session) return;

        const { data: userData, error } = await supabase
          .from('users')
          .select('user_name')
          .eq('id', session.user.id)
          .single();

        if (error) throw error;
        setUserName(userData.user_name || '');
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUserData();
  }, []);

  useEffect(() => {
    const checkAdminStatus = async () => {
      try {
        const { data: { session } } = await supabase.auth.getSession();
        if (!session) return;

        const { data: userData, error } = await supabase
          .from('users')
          .select('is_admin')
          .eq('id', session.user.id)
          .single();

        if (error) throw error;
        setIsAdmin(userData.is_admin);

        if (userData.is_admin) {
          await fetchAdminStats();
        }
      } catch (error) {
        console.error('Error checking admin status:', error);
      }
    };

    checkAdminStatus();
  }, []);

  const fetchAdminStats = async () => {
    setStatsLoading(true);
    try {
      // Get total user count
      const { data: users, error: usersError } = await supabase
        .from('users')
        .select('id, klaviyo_api_key');
      
      if (usersError) throw usersError;

      // Get all users' Klaviyo data
      const allPropertiesSet = new Set();
      const allValuesSet = new Set();
      let totalProfiles = 0;

      for (const user of users) {
        if (!user.klaviyo_api_key) continue;

        try {
          const { data: { session } } = await supabase.auth.getSession();
          if (!session) throw new Error('No session found');

          const response = await axios.post(klaviyoProxyUrl, {
            klaviyoApiKey: user.klaviyo_api_key,
            endpoint: '/api/v2/people'
          }, {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${session.access_token}`
            }
          });

          if (response.data && response.data.data) {
            totalProfiles += response.data.data.length;
            
            response.data.data.forEach(profile => {
              const attributes = profile.attributes;
              if (attributes && attributes.properties) {
                Object.entries(attributes.properties)
                  .forEach(([key, value]) => {
                    allPropertiesSet.add(key);
                    allValuesSet.add(typeof value === 'object' ? JSON.stringify(value) : String(value));
                  });
              }
            });
          }
        } catch (error) {
          console.error(`Error fetching data for user ${user.id}:`, error);
        }
      }

      setAdminStats({
        totalUsers: users.length,
        totalProperties: allPropertiesSet.size,
        totalValues: allValuesSet.size,
        totalProfiles: totalProfiles
      });

    } catch (error) {
      console.error('Error fetching admin stats:', error);
    } finally {
      setStatsLoading(false);
    }
  };

  const handleViewProperty = async (property) => {
    if (!profileData || !Array.isArray(profileData)) {
      console.error('No profile data available');
      return;
    }

    // Find profiles that have this property
    const profilesWithProperty = profileData.filter(profile => {
      const value = profile.attributes?.properties?.[property.name];
      return value !== undefined && value !== null;
    });

    console.log('Sample full profile:', JSON.stringify(profilesWithProperty[0], null, 2));

    const enrichedProperty = {
      ...property,
      profiles: profilesWithProperty.map(profile => {
        // Extract email from the correct location in the profile data
        const profileAttributes = profile.attributes || {};
        const email = profileAttributes.email || 
                     profileAttributes.$email || 
                     profileAttributes.properties?.['$email'] ||
                     profileAttributes.properties?.email;

        const profileData = {
          id: profile.id,
          email: email || 'No email available',
          value: profile.attributes?.properties?.[property.name]
        };
        
        console.log('Processing profile:', {
          profileId: profile.id,
          rawAttributes: profile.attributes,
          extractedEmail: email,
          extractedData: profileData,
          fullProfile: profile
        });
        
        return profileData;
      })
    };

    console.log('Final enriched property:', enrichedProperty);

    // Navigate to the property breakdown page with the property data
    navigate('/property-breakdown', { state: { property: enrichedProperty } });
  };

  // Get first name (text before first space)
  const firstName = userName.split(' ')[0];

  // Filter and sort properties
  const filteredProperties = customProperties
    .filter(property => 
      property.name.toLowerCase().includes(searchQuery.toLowerCase())
    )
    .sort((a, b) => {
      // Check if either property starts with $
      const aStartsWithDollar = a.name.startsWith('$');
      const bStartsWithDollar = b.name.startsWith('$');
      
      // If one starts with $ and the other doesn't, sort $ last
      if (aStartsWithDollar !== bStartsWithDollar) {
        return aStartsWithDollar ? 1 : -1;
      }
      
      // Otherwise sort alphabetically
      return a.name.localeCompare(b.name);
    });

  // Get current page items
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredProperties.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(filteredProperties.length / itemsPerPage);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: '2-digit'
    });
    const formattedTime = date.toLocaleTimeString('en-GB', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false
    });
    return `${formattedDate} ${formattedTime}`;
  };

  return (
    <div className="home-container">
      <h1 className="welcome-message">Welcome, {userName?.split(' ')[0] || 'User'}</h1>
      
      {error && <div className="error-message">{error}</div>}
      
      {!loading && !error && (
        <div className="data-container">
          {isAdmin && (
            <div className="stats-section">
              <h2>Overall Statistics</h2>
              <div className="stats-grid">
                <div className="stat-box">
                  <h4>Users</h4>
                  {statsLoading ? (
                    <div className="loading-spinner"></div>
                  ) : (
                    <p>{adminStats?.totalUsers || 0}</p>
                  )}
                </div>
                <div className="stat-box">
                  <h4>Properties</h4>
                  {statsLoading ? (
                    <div className="loading-spinner"></div>
                  ) : (
                    <p>{adminStats?.totalProperties || 0}</p>
                  )}
                </div>
                <div className="stat-box">
                  <h4>Values</h4>
                  {statsLoading ? (
                    <div className="loading-spinner"></div>
                  ) : (
                    <p>{adminStats?.totalValues || 0}</p>
                  )}
                </div>
                <div className="stat-box">
                  <h4>Profiles</h4>
                  {statsLoading ? (
                    <div className="loading-spinner"></div>
                  ) : (
                    <p>{adminStats?.totalProfiles || 0}</p>
                  )}
                </div>
              </div>
            </div>
          )}
          
          <div className="user-data">
            <h2>Your Properties</h2>
            <div className="table-container">
              <div className="search-container">
                <input
                  type="text"
                  placeholder="Search properties..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  className="search-input"
                />
              </div>
              <table className="data-table">
                <thead>
                  <tr>
                    <th>Property</th>
                    <th>Count</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {currentItems.map((property, index) => (
                    <tr key={index} className={property.isCustom ? 'custom-property' : 'global-property'}>
                      <td>{property.name}</td>
                      <td>{property.count}</td>
                      <td>
                        <button
                          className="view-button"
                          onClick={() => handleViewProperty(property)}
                        >
                          View
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {totalPages > 1 && (
                <div className="pagination">
                  <button 
                    onClick={() => setCurrentPage(currentPage - 1)}
                    disabled={currentPage === 1}
                    className="pagination-button"
                  >
                    Previous
                  </button>
                  <span>Page {currentPage} of {totalPages}</span>
                  <button 
                    onClick={() => setCurrentPage(currentPage + 1)}
                    disabled={currentPage === totalPages}
                    className="pagination-button"
                  >
                    Next
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {loading && (
        <div className="loading-container">
          <div className="progress-bar">
            <div 
              className="progress-bar-fill" 
              style={{ width: `${loadingProgress}%` }}
            />
          </div>
          <p className="loading-message">{loadingMessage}</p>
          {loadingProgress < 100 && <p>Loading profile data...</p>}
        </div>
      )}
    </div>
  );
};

export default Home;
