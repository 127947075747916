import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import { IconHome, IconLink, IconSettings, IconBook, IconUsers, IconLogout, IconUserPlus, IconPhone, IconRobot } from '@tabler/icons-react';
import { AcademicCapIcon } from '@heroicons/react/24/solid';
import { useKlaviyo } from '../context/KlaviyoContext';
import logo from '../assets/images/moralelogo.png';
import '../styles/components/Layout.css';
import '../styles/components/Sidebar.css';

const Sidebar = ({ userInfo }) => {
  const navigate = useNavigate();
  const { resetKlaviyoData } = useKlaviyo();

  console.log('Sidebar userInfo:', userInfo);
  console.log('Sidebar is_admin:', userInfo?.is_admin);

  const handleLogout = async () => {
    try {
      resetKlaviyoData();
      await supabase.auth.signOut();
      navigate('/login', { replace: true });
    } catch (error) {
      console.error('Logout error:', error);
      // Force navigation even on error
      navigate('/login', { replace: true });
    }
  };

  return (
    <div className="sidebar">
      <div className="logo-container">
        <img src={logo} alt="Logo" className="logo" />
      </div>

      <nav className="nav-links">
        <NavLink to="/home" className={({ isActive }) => isActive ? 'nav-link active' : 'nav-link'}>
          <IconHome className="nav-icon" />
          <span>Home</span>
        </NavLink>

        <NavLink to="/link-generator" className={({ isActive }) => isActive ? 'nav-link active' : 'nav-link'}>
          <IconLink className="nav-icon" />
          <span>Link Generator</span>
        </NavLink>

        <NavLink to="/morale-ai" className={({ isActive }) => isActive ? 'nav-link active' : 'nav-link'}>
          <AcademicCapIcon className="nav-icon" />
          <span>Morale AI</span>
        </NavLink>

        <NavLink to="/settings" className={({ isActive }) => isActive ? 'nav-link active' : 'nav-link'}>
          <IconSettings className="nav-icon" />
          <span>Settings</span>
        </NavLink>

        <NavLink to="/user-guide" className={({ isActive }) => isActive ? 'nav-link active' : 'nav-link'}>
          <IconBook className="nav-icon" />
          <span>User Guide</span>
        </NavLink>

        {Boolean(userInfo?.is_admin) && (
          <>
            <NavLink to="/admin/new-user" className={({ isActive }) => isActive ? 'nav-link active' : 'nav-link'}>
              <IconUserPlus className="nav-icon" />
              <span>New User</span>
            </NavLink>

            <NavLink to="/admin/user-management" className={({ isActive }) => isActive ? 'nav-link active' : 'nav-link'}>
              <IconUsers className="nav-icon" />
              <span>User Management</span>
            </NavLink>
          </>
        )}
      </nav>

      <a 
        href="https://calendly.com/aldwyn-morale/morale-support" 
        target="_blank" 
        rel="noopener noreferrer" 
        className="nav-link"
      >
        <IconPhone className="nav-icon" />
        <span>Contact</span>
      </a>

      <button onClick={handleLogout} className="btn-logout">
        <IconLogout className="nav-icon" />
        <span>Logout</span>
      </button>

    </div>
  );
};

export default Sidebar;
