import React, { createContext, useState, useContext } from 'react';

const KlaviyoContext = createContext();

export const KlaviyoProvider = ({ children }) => {
  const [profileData, setProfileData] = useState(null);
  const [customProperties, setCustomProperties] = useState([]);
  const [loading, setLoading] = useState(false);
  const [lastRefresh, setLastRefresh] = useState(null);

  const resetKlaviyoData = () => {
    setProfileData(null);
    setCustomProperties([]);
    setLoading(false);
    setLastRefresh(null);
  };

  const value = {
    profileData,
    setProfileData,
    customProperties,
    setCustomProperties,
    loading,
    setLoading,
    lastRefresh,
    setLastRefresh,
    resetKlaviyoData
  };

  return (
    <KlaviyoContext.Provider value={value}>
      {children}
    </KlaviyoContext.Provider>
  );
};

export const useKlaviyo = () => {
  const context = useContext(KlaviyoContext);
  if (context === undefined) {
    throw new Error('useKlaviyo must be used within a KlaviyoProvider');
  }
  return context;
};
