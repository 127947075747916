import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { supabase } from './supabaseClient';
import Sidebar from './components/Sidebar';
import Login from './pages/Login';
import Home from './pages/Home';
import Settings from './pages/Settings';
import LinkGenerator from './pages/LinkGenerator';
import UserGuide from './pages/UserGuide';
import NewUser from './pages/admin/NewUser';
import UserManagement from './pages/admin/UserManagement';
import PropertyBreakdown from './pages/PropertyBreakdown';
import MoraleAI from './pages/MoraleAI'; // Added import statement
import { KlaviyoProvider } from './context/KlaviyoContext';
import './styles/global.css';

function App() {
  const [session, setSession] = useState(null);
  const [userInfo, setUserInfo] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      if (session) {
        setSession(session);
        supabase
          .from('users')
          .select('*')
          .eq('id', session.user.id)
          .single()
          .then(({ data, error }) => {
            if (data) {
              setUserInfo({
                ...data,
                is_admin: Boolean(data.is_admin)
              });
            }
            setLoading(false);
          })
          .catch(() => {
            setLoading(false);
          });
      } else {
        setLoading(false);
      }
    });

    const { data: { subscription } } = supabase.auth.onAuthStateChange((event, session) => {
      setSession(session);
      
      if (session) {
        supabase
          .from('users')
          .select('*')
          .eq('id', session.user.id)
          .single()
          .then(({ data }) => {
            if (data) {
              setUserInfo({
                ...data,
                is_admin: Boolean(data.is_admin)
              });
            }
          });
      } else {
        setUserInfo(null);
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  if (loading) {
    return (
      <div style={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        height: '100vh',
        fontSize: '18px',
        color: '#333'
      }}>
        Loading...
      </div>
    );
  }

  return (
    <Router>
      <KlaviyoProvider>
        {!session ? (
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="*" element={<Navigate to="/login" replace />} />
          </Routes>
        ) : (
          <div className="app">
            <Sidebar userInfo={userInfo} />
            <div className="content">
              <Routes>
                <Route path="/login" element={<Navigate to="/home" replace />} />
                <Route path="/home" element={<Home userInfo={userInfo} />} />
                <Route path="/settings" element={<Settings userInfo={userInfo} />} />
                <Route path="/link-generator" element={<LinkGenerator userInfo={userInfo} />} />
                <Route path="/morale-ai" element={<MoraleAI userInfo={userInfo} />} />
                <Route path="/user-guide" element={<UserGuide userInfo={userInfo} />} />
                <Route path="/property-breakdown" element={<PropertyBreakdown userInfo={userInfo} />} />
                {userInfo?.is_admin && (
                  <>
                    <Route path="/admin/new-user" element={<NewUser userInfo={userInfo} />} />
                    <Route path="/admin/user-management" element={<UserManagement userInfo={userInfo} />} />
                  </>
                )}
                <Route path="/" element={<Navigate to="/home" replace />} />
                <Route path="*" element={<Navigate to="/home" replace />} />
              </Routes>
            </div>
          </div>
        )}
      </KlaviyoProvider>
    </Router>
  );
}

export default App;
